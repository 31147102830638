/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import styles from "./styles/styles.module.css";
import Logo from "./images/marqu.png";
import OurApproach from "./images/coplap.png";
import Services from "./images/services.png";
import Submit from "./images/landing.png";
import { Link, animateScroll as scroll } from "react-scroll";
import successAnimation from "../src/animation/success.json";

const HomeTwo = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://geekraji.com/api/cap9consulting",
        formData
      );
      console.log("Form submitted successfully:", response.data);
      setFormData({ name: "", email: "", message: "" });
      setShowSuccessAnimation(true);
      setTimeout(() => {
        setShowSuccessAnimation(false);
      }, 3000); // Hide animation after 3 seconds
    } catch (error) {
      console.error("Error submitting form:", error);
      // You can add an error message here
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.consulting}>
        <div className={styles.navWrap}>
          <div className={styles.nav}>
            <Link
              className={styles.con}
              to="ourApproach"
              smooth={true}
              duration={500}
            >
              APPROACH
            </Link>
            <Link
              className={styles.con}
              to="ourServices"
              smooth={true}
              duration={500}
            >
              SERVICES
            </Link>
            <Link
              className={styles.con}
              to="subContact"
              smooth={true}
              duration={500}
            >
              CONTACT
            </Link>
          </div>
        </div>
        <h5 className={styles.cap9}>
          Cap<span className={styles.subtext}>9</span> Consulting
        </h5>
      </div>
      <div className={styles.BusCon}>
        <div>
          {isMobile ? (
            <p className={styles.busFinM}>
              Business consultancy at it's finest
            </p>
          ) : (
            <p className={styles.busFinW}>
              Business consultancy <br /> at it's finest
            </p>
          )}

          {!isMobile && (
            <div className={styles.busapp}>
              <p>APPROACH</p>
              <p>SERVICES</p>
            </div>
          )}
        </div>

        <div className={styles.BusConRight}>
          <p>
            Our firm comprises of partners with extensive consulting experience
            working for global consulting firms such as Deloitte, Booz Allen
            Hamilton and Hitachi Consulting on numerous cross-border
            transformational projects.
          </p>
          <p>
            Cap9Consulting is a business and technology consulting company that
            partners with our clients to initiate, develop, implement and
            sustain significant improvements in corporate performance.
          </p>
        </div>
      </div>
      <div className={styles.marqueeWrap}>
        <marquee>
          <img src={Logo} alt="logo" />
          <img src={Logo} alt="logo" />
          <img src={Logo} alt="logo" />
        </marquee>
      </div>
      <div className={styles.ourApproach} id="ourApproach">
        <img src={OurApproach} alt="" />
        <div className={styles.ourApproachRight}>
          <h3>Our Approach</h3>
          <p>
            Our consultants combine their specialized skills with the firm’s
            proven problem- solving approach, ensuring that we understand our
            client’s mission and objectives. Our consulting approach, combined
            with client insight and technology acumen, provides us with the
            unique ability to listen, design, develop and implement the optimal
            solution for our clients
          </p>
          <p>
            Our approach is framed by the key distinction that we don’t have
            customers, we serve clients. Our ability to meet and exceed client
            expectations originates in a well- established collaborative culture
            uniquely supported by the firm’s operating model.
          </p>

          <div className={styles.contactUsWrap}>
            <p className={styles.contactUs}>CONTACT US</p>
          </div>
        </div>
      </div>
      <div className={styles.services}>
        <img src={Services} alt="service" />
      </div>
      <div className={styles.OurServicesWrap} id="ourServices">
        <div className={styles.OurServices}>
          <div className={styles.OurServicesLeft}>
            <p>Investment Advisory</p>
          </div>
          <div className={styles.OurServicesRight}>
            <p>
              {" "}
              At Cap9 Consulting we believe that true value is unlocked when you
              match strategic investment opportunities to the risk tolerance of
              your clients to drive real value.
            </p>
          </div>
        </div>

        <div className={styles.OurServices}>
          <div className={styles.OurServicesLeft}>
            <p>Strategy</p>
          </div>
          <div className={styles.OurServicesRight}>
            <p>
              {" "}
              Strategy is about decisions. By working side by side with our
              clients, we are able to collaboratively make the choices that will
              help stretch and sharpen their ambition - grounded firmly in the
              realities of the marketplace, the capabilities of their
              organization and the goals of their stakeholders.
            </p>
          </div>
        </div>

        <div className={styles.OurServices}>
          <div className={styles.OurServicesLeft}>
            <p>Business Technology</p>
          </div>
          <div className={styles.OurServicesRight}>
            <p>
              {" "}
              Cap9Consulting experience with business intelligence is rooted in
              the origins of BI and decision support software systems.
            </p>
          </div>
        </div>

        <div className={styles.OurServices}>
          <div className={styles.OurServicesLeft}>
            <p>Operations</p>
          </div>
          <div className={styles.OurServicesRight}>
            <p>
              {" "}
              Cap9 Consulting helps clients implement operational change within
              their business and are at the heart of solving some of their most
              difficult and fundamental issues.
            </p>
          </div>
        </div>

        <div className={styles.OurServices}>
          <div className={styles.OurServicesLeft}>
            <p>Outsourcing & Business Process Offshoring</p>
          </div>
          <div className={styles.OurServicesRight}>
            <p>
              {" "}
              Outside the norm of IT consulting, business process outsourcing
              (BPO) is becoming the choice among companies actively seeking ways
              to create sustainable cost benefits and increase operational
              efficiencies, thus making sure that the quality of service
              increases, allowing increase in shareholder value and keeping
              focus on their core business capabilities.
            </p>
          </div>
        </div>

        <div className={styles.OurServices}>
          <div className={styles.OurServicesLeft}>
            <p>IT Strategy</p>
          </div>
          <div className={styles.OurServicesRight}>
            <p>
              {" "}
              Today's CIO is faced with ever increasing demands by the
              constantly changing global economic landscape. Technology
              continues to infiltrate and demand its presence as a major force
              in any business competitive advantage.
              <br />
              <br />
              <br />
              The ability to take advantage of innovative and new age technology
              is instrumental to cost reduction and the ability to respond to
              the demands of the market place. Cutting edge businesses realize
              that technology is not just an enabler of operations both internal
              and external; technology is a key component to staying ahead of
              the competition both near and far. Organization leadership as well
              as their CIOs are presented with the opportunity to elevate the
              status of IT within their organization.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.submitContact} id="subContact">
        <div className={styles.submitContactLeft}>
          <img src={Submit} alt="submit" />
        </div>
        <div className={styles.submitContactRight}>
          <h4>Contact</h4>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              className={styles.inputs}
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Your Email"
              className={styles.inputs}
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              placeholder="Message"
              className={styles.textarea}
              required
            />

            <div className={styles.subwrap}>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>

        {showSuccessAnimation && (
          <div className={styles.successAnimationOverlay}>
            <div className={styles.successAnimationContent}>
              <Lottie
                animationData={successAnimation}
                loop={false}
                style={{ width: 200, height: 200 }}
              />
             
            </div>
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          <div className={styles.footerLeftInner}>
            <p>US OFFICE</p>
            <p>+1 404-918-5322</p>
          </div>

          <div className={styles.footerLeftInner}>
            <p>NIGERIA OFFICE</p>
            <p>+234 810 9605 327</p>
          </div>
          <div></div>
        </div>
        <div className={styles.footerRight}>
          <div className={styles.footerRightInner}>
            <p>GEORGIA</p>
            <p>185 Brookeivey Lane, Alpharetta,</p>
            <p>Alpharatta, GA, 30009</p>
          </div>

          <div className={styles.footerRightInner}>
            <p>LAGOS</p>
            <p>9 Adewale Kolawole Street,</p>
            <p>Lekki 1, Lagos, Lagos State</p>
          </div>

          <div className={styles.footerRightInner}>
            <p>GHANA</p>
            <p>PO BOX KA9852, Desgign House,</p>
            <p>Dnauau Circle, Accra, Ghana</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTwo;
