import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './home';
import HomeTwo from './homeTwo';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeTwo />} />
        {/* <Route path="/homenew" element={<HomeTwo />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
